<template>
	<div class="py-4 px-2" style="display:flex;padding: 20px;height: 100%;overflow-y: auto;">
		<div style="flex:1;">
			<el-form :model="formData" :rules="formRules" label-width="1.3rem" ref="form"
				style="padding-bottom: 130px;">
				<el-form-item label="新闻标题" required :rules="formRules.name" prop="name">
					<el-input v-model="formData.name" placeholder="请输入" />
				</el-form-item>
				<el-form-item label="新闻来源">
					<el-input v-model="formData.laiyuan" placeholder="请输入" style="width: 202px;" />
				</el-form-item>
				<el-form-item label="所属分类" prop="classify_id" clearable required>
					<el-cascader v-model="fenlei_ids" :props="props" @change="changejilian"></el-cascader>
				</el-form-item>
				<el-form-item label="新闻简介">
					<el-input v-model="formData.jianjie" placeholder="请输入" />
				</el-form-item>
				<el-form-item label="新闻内容" required>
					<div style="border: 1px solid #ccc;width: 100%;">
						<Toolbar style="border-bottom: 1px solid #ccc" :editor="editor" :defaultConfig="toolbarConfig"
							mode="default" />
						<Editor style="height: 500px; overflow-y: hidden;font-size: 16px;" v-model="formData.content"
							:defaultConfig="editorConfig" mode="default" @onCreated="onCreated" />
					</div>
				</el-form-item>
				<el-form-item label="上传附件" class="fjbox" style="display: flex;align-items: flex-start;">
					<el-upload
					  class="upload-demo"
					  :action="baseurl+'/api/login/upload'" :on-success="handleAvatarSuccess2"
					  :on-remove="handleChange2"
					  :file-list="fileList">
					  <el-button size="small" type="primary">点击上传</el-button>
					</el-upload>
				</el-form-item>
				<el-form-item label="外部地址">
					<el-input v-model="formData.tzurl" placeholder="请输入" />
				</el-form-item>
				<el-form-item label="缩略图">
					<el-upload class="avatar-uploader" :action="baseurl + '/api/login/upload'" :show-file-list="false"
						:on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
						<img v-if="imageUrl" :src="imageUrl" class="avatar">
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
				</el-form-item>
				<el-form-item label="发布时间" required>
					<el-date-picker v-model="formData.fbtime" type="datetime" value-format="yyyy-MM-dd HH:mm"
						placeholder="选择时间">
					</el-date-picker>
				</el-form-item>
				<el-form-item label="排序">
					<el-input v-model="formData.weigh" type="number" placeholder="请输入" style="width: 202px;" />
				</el-form-item>

				<div class="mt-16 pl-16" style="padding: 0 20px;">
					<el-button type="primary" @click="onSubmit"> 发布 </el-button>
					<!-- <el-button type="info" @click="onCancel"> 取消 </el-button> -->
				</div>
			</el-form>
		</div>
	</div>
</template>
<script>
	import {
		Message
	} from 'element-ui'
	import {
		Editor,
		Toolbar
	} from '@wangeditor/editor-for-vue'
	export default {
		components: {
			Editor,
			Toolbar
		},
		data() {
			let that = this;
			return {
				baseurl: '',
				formDatalist: [], //其他新闻
				fenlei_ids:[],
				formData: {
					name: '',
					laiyuan: '',
					classify_id: '',
					pid:'',
					content: '',
					tzurl: '',
					slturl: '',
					weigh: '',
					fbtime: '',
					jianjie: '',
					fujian:''
				},
				imageUrl: '', //缩略图
				formRules: {
					name: [{
						required: true,
						message: "标题不能为空",
						whitespace: true
					}],
					jianjie: [{
						required: true,
						message: "简介不能为空",
						whitespace: true
					}],
					laiyuan: [{
						required: true,
						message: "来源不能为空",
						whitespace: true
					}],
					classify_id: [{
						required: true,
						message: '请选择',
						trigger: 'change'
					}],
					content: [{
						required: true,
						message: "内容不能为空",
						whitespace: true
					}]
				},
				classifyData: [], //类型


				//富文本相关
				editor: null,
				toolbarConfig: {},
				editorConfig: {
					placeholder: '请输入内容...',
					MENU_CONF: {
						uploadImage: {
							server: this.$URL + '/api/login/upload',
							fieldName: 'file',
							// 单个文件的最大体积限制，默认为 2M
							maximgSize: 10 * 1024 * 1024, // 10M
							// 最多可上传几个文件，默认为 100
							maxNumberOfimgs: 100,
							// 选择文件时的类型限制，默认为 ['image/*'] 。如不想限制，则设置为 []
							allowedimgTypes: [],
							// 自定义上传参数，例如传递验证的 token 等。参数会被添加到 formData 中，一起上传到服务端。
							meta: {

								// otherKey: 'yyy'
								// img:''
							},
							// 将 meta 拼接到 url 参数中，默认 false
							metaWithUrl: false,

							// 自定义增加 http  header
							headers: {
								token: this.$store.state.userinfo.token
								// otherKey: 'xxx'
							},

							// 跨域是否传递 cookie ，默认为 false
							withCredentials: false,

							// 超时时间，默认为 10 秒
							timeout: 10 * 1000, //10 秒

							// 上传前
							onBeforeUpload(imgs) {
								Message({
									message: '图片正在上传中,请耐心等待',
									grouping: true,
									duration: 0,
									customClass: 'uploadTip',
									iconClass: 'el-icon-loading',
									showClose: true
								});
								return imgs;
							},
							// 自定义插入图片
							customInsert(res, insertFn) {
								// 因为自定义插入导致onSuccess与onFailed回调函数不起作用,自己手动处理
								// 先关闭等待的ElMessage
								Message.closeAll();

								if (res.code === 200) {
									Message.success({
										message: "图片上传成功",
										grouping: true,
									});
								} else {
									Message.error({
										message: "图片上传失败，请重新尝试",
										grouping: true,
									});
								}
								// 从 res 中找到 url alt href ，然后插入图片
								insertFn(res.fullurl);
								// console.log(res, "res.data")
							},

							// 单个文件上传成功之后
							onSuccess(img, res) {
								console.log(`${img.name} 上传成功`, res);
							},

							// 单个文件上传失败
							onFailed(img, res) {
								console.log(`${img.name} 上传失败`, res);
							},

							// 上传进度的回调函数
							onProgress(progress) {
								console.log('progress', progress);
								// progress 是 0-100 的数字
							},

							// 上传错误，或者触发 timeout 超时
							onError(img, err, res) {
								console.log(`${img.name} 上传出错`, err, res);
							}
						},

						// 上传视频
						uploadVideo: {
							server: this.$URL + '/api/login/upload',
							fieldName: "file",
							// 单个文件的最大体积限制，默认为 10M
							maxFileSize: 500 * 1024 * 1024, // 500M
							// 最多可上传几个文件，默认为 5
							maxNumberOfFiles: 3,
							// 选择文件时的类型限制，默认为 ['video/*'] 。如不想限制，则设置为 []
							allowedFileTypes: ["video/*"],
							// 将 meta 拼接到 url 参数中，默认 false
							metaWithUrl: false,
							// 自定义增加 http  header
							headers: {
								token: this.$store.state.userinfo.token
								// otherKey: 'xxx'
							},
							// 跨域是否传递 cookie ，默认为 false
							withCredentials: false,
							// 超时时间，默认为 30 秒
							timeout: 30 * 1000, // 15 秒
							onBeforeUpload(imgs) {
								Message({
									message: '正在上传中,请耐心等待',
									grouping: true,
									duration: 0,
									customClass: 'uploadTip',
									iconClass: 'el-icon-loading',
									showClose: true
								});
								return imgs;
							},
							// 自定义插入图片
							customInsert(res, insertFn) {
								// 因为自定义插入导致onSuccess与onFailed回调函数不起作用,自己手动处理
								// 先关闭等待的ElMessage
								Message.closeAll();

								if (res.code === 200) {
									Message.success({
										message: "视频上传成功",
										grouping: true,
									});
								} else {
									Message.error({
										message: "上传失败，请重新尝试",
										grouping: true,
									});
								}
								// 从 res 中找到 url alt href ，然后插入图片
								insertFn(res.fullurl);
								// console.log(res, "res.data")
							},
							// 上传进度的回调函数
							onProgress(progress) {
								console.log("progress", progress);
							},
							// 单个文件上传成功之后
							onSuccess(file, res) {
								console.log(`${file.name} 上传成功`, res);
							},
							// 单个文件上传失败
							onFailed(file, res) {
								console.log(`${file.name} 上传失败`, res);
							},
							// 上传错误，或者触发 timeout 超时
							onError(file, err, res) {
								console.log(`${file.name} 上传出错`, err, res);
							}
						}
					}
				},

				id:0,
				props: {
					lazy: true,
					lazyLoad(node, resolve) {
						that.$post({
							url: '/api/classify/index',
							params: {
								pid: node.level==0?0:node.value,
								p: 1,
								size: 9999
							}
						}).then((res) => {
							res.list = res.list.map(item => ({
									value: item.id,
									label: item.name,
									leaf: item.ej.length>0?false:true
								}))
							resolve(res.list);
						})
					}
				},
				fileList:[]
			};
		},
		created() {
			this.baseurl = this.$URL			
		},
		methods: {
			handleAvatarSuccess2(res, file) {
				let obj ={
					name:res.file_name,
					url:res.fullurl,
					id:res.id
				}
				this.fileList.push(obj)
			},
			handleChange2(file, fileList) {
				this.fileList = fileList			    
			},
			changejilian(e){
				if(this.fenlei_ids.length>=2){//有二级
					this.formData.classify_id = this.fenlei_ids[1]
					this.formData.pid = this.fenlei_ids[0]
				}else{//没有二级
					this.formData.classify_id = this.fenlei_ids[0]
				}
			},
			onSubmit() {
				if (!this.formData.fbtime) {
					this.$message.warning('请选择发布时间')
					return
				}
				if(this.fileList.length>0){
					let fj_ids = this.fileList.map(item=>{return item.id})
					this.formData.fujian = fj_ids.join(',')
				}
				this.$refs.form.validate((valid) => {
					if (valid) {
						console.log(this.formData)
						this.$post({
							url: '/api/news/qxadd',
							params: this.formData
						}).then((res) => {
							this.$message.success('操作成功')
							
						})
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			handleAvatarSuccess(res, file) {
				this.formData.slturl = res.id
				this.imageUrl = URL.createObjectURL(file.raw);
			},
			beforeAvatarUpload(file) {
				const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
				const isLt2M = file.size / 1024 / 1024 < 10;

				if (!isJPG) {
					this.$message.error('上传图片只能是 JPG或PNG 格式!');
				}
				if (!isLt2M) {
					this.$message.error('上传图片大小不能超过 10MB!');
				}
				return isJPG && isLt2M;
			},
			onCancel() {
				this.$router.push('/system/news')
			},
			onCreated(editor) {
				this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
			},
		},
		beforeDestroy() {
			const editor = this.editor
			if (editor == null) return
			editor.destroy() // 组件销毁时，及时销毁编辑器
		}
	};
</script>

<style lang="scss" scoped>
	::v-deep .fjbox .el-form-item__content{
		margin-left: 0 !important;
	}
	::v-deep .avatar-uploader .el-upload {
		border: 1px dashed #999;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 145px;
		height: 145px;
		line-height: 145px;
		text-align: center;
	}

	.avatar {
		width: 145px;
		height: 145px;
		display: block;
	}

	::v-deep .el-icon-circle-check:before {
		font-size: 0.2rem;
	}

	::v-deep .el-icon-circle-check {
		font-size: 0.2rem !important;
		line-height: 0.16rem;
		height: 0.16rem;
	}

	::v-deep .el-dialog__header {
		display: flex;
	}

	.filenamebox:hover {
		color: #409EFF;
	}
</style>